app-nodes-edit-panel,
app-sidebar {
    .mat-expansion-panel-spacing {
        margin: 0;
    }
    .mat-expansion-panel {
        box-shadow: none !important;
        transition: all 1s !important;
        &:hover {
            box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.2);
        }
        &.panel-layers,
        &.panel-tools {
            .mat-expansion-panel-body {
                padding: 0;
            }
        }
        .mat-expansion-panel-body {
            padding: 0 12px 8px;
        }
        .mat-expansion-panel-header {
            position: relative;
            // height: 35px;
            padding: 0 12px;
            .mat-expansion-panel-header-title,
            mat-panel-title {
                font-weight: bold;
                font-family: 'Proxima Nova';
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.0710702px;
                color: #4A4A4A;
                text-transform: uppercase;
                position: relative;
                // &:before,
                &:after {
                    content: ' ';
                    width: 100%;
                    margin: 0 15px;
                    border: 1px solid #4A4A4A;
                    opacity: .3;
                }
            }
            &.mat-expanded {
                .mat-expansion-panel-header-title,
                mat-panel-title {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

// app-details-edit-panel {
//     .mat-expansion-panel-header-title,
//     mat-panel-title {
//         &:before {
//             content: ' ';
//         }
//     }
// }
// app-sidebar {
//     .mat-expansion-panel-header-title,
//     mat-panel-title {
//         &:after {
//             content: ' ';
//         }
//     }
// }
