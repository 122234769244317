.mat-mdc-tooltip {
    --mdc-plain-tooltip-container-color: #616161;
    --mdc-plain-tooltip-supporting-text-color: white;
    font-size: .8em;
}

.mat-mdc-tooltip-panel-below {
    .mat-mdc-tooltip {
        &::before {}
    }
}