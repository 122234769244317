.preloader {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, .4);
}

.spinner-container {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, .6);

  .mat-spinner {
    margin: 100px auto;
  }
}

.spinner {
  width: 40px;
  height: 40px;
  top: 35%;
  position: relative;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #1976d2;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}
@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0.0);
  }

  50% {
    -webkit-transform: scale(1.0);
  }
}
@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }

  50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

//.time-container,
//.time-container:before,
.icon-scroll,
.icon-scroll:before {
  position: absolute;
  left: 50%;
}

//.time-container,
.icon-scroll {
  width: 40px;
  height: 70px;
  margin-left: -20px;
  top: 50%;
  margin-top: -35px;
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 25px;
}

//.time-container:before,
.icon-scroll:before {
  content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
}

@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}
@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}
