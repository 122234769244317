/* Add application styles & imports to this file! */

mat-tab-group {
    .mat-mdc-tab-label-container {
        border-bottom: 2px solid gray;
    }
    mat-tab-body {
        padding: 15px 0;
        &.p-30 {
            padding: 30px 0;
        }
    }
}

.mat-tab-body.mat-tab-body-active {
    animation: fade-blur .2s;
}

mat-row.mat-row {
    animation: fade-blur .2s;
}


/* animation: fade 1s; */

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


/* animation: fade .4s; */

@keyframes blur {
    0% {
        filter: blur(4px);
    }
    100% {
        filter: blur(0);
    }
}

@keyframes fade-blur {
    0% {
        opacity: .6;
        filter: blur(4px);
    }
    100% {
        opacity: 1;
        filter: blur(0);
    }
}

// .mat-tab-group .mat-tab-header .mat-tab-label {
//     letter-spacing: -0.5px;
// }
