.imus-table {
    &__header-row {
        &.mat-mdc-header-row.mdc-data-table__header-row {
            max-height: 42px;
            min-height: 42px;
        }
    }

    &__row {
        &.mat-mdc-row.mdc-data-table__row {
            max-height: 32px;
            min-height: 32px;
        }
    }

    &__cell-content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .mat-mdc-row {
        background-color: unset;
        background-image: unset;

        &:nth-child(odd) {
            background-color: unset;
            background-image: unset;
        }
    }

    .mat-mdc-tab-group-dynamic-height .mat-mdc-tab-body-content {
        overflow: auto;
    }

    .mdc-data-table {
        &__header-cell,
        &__cell {
            border: 1px solid #8c8c8c8c;
            padding: 0 3px;
        }

        &__cell {
            background-color: #fff;

            &.selected-cell {
                background-color: #acfff7;
            }

            &.editable-field {
                position: relative;
                border: 2px solid #1e88e5;

                .imus-table__cell-content {
                    display: none;
                }
            }
        }

        &__table {
            padding: 0;
        }

        &__header-cell.mat-mdc-header-cell {
            background: #e9e9e9;
        }
    }

    .mat-mdc-header-cell,
    .mat-mdc-cell {
        min-width: 90px;
        box-sizing: border-box;
    }

    .mat-sort-header-container {
        width: 100%;
        justify-content: space-between;
    }

    .mat-mdc-cell.mat-column-id,
    .mat-mdc-footer-cell.mat-column-id,
    .mat-mdc-header-cell.mat-column-id {
        max-width: 30px;
        min-width: 30px;
        background-color: #e9e9e9;
    }

    .mat-mdc-tab-header {
        .mat-mdc-tab-labels {
            border-top: 1px solid #8c8c8c8c;
            height: 30px;
        }
    }

    .mat-mdc-tab-body {
        background-color: #e9e9e9;
        border-top: 1px solid #8c8c8c8c;
        border-right: 1px solid #8c8c8c8c;
        border-left: 1px solid #8c8c8c8c;
        padding-bottom: 2px;
    }

    .mdc-tab.mat-mdc-tab {
        height: 20px;

        .mdc-tab-indicator__content--underline {
            border-color: #8c8c8c8c;
        }

        .mdc-tab-indicator__content {
            //opacity: 1;
        }

        &.mdc-tab--active {
            background-color: #e9e9e9;
            border-bottom: 1px solid #8c8c8c8c;
            border-right: 1px solid #8c8c8c8c;
            border-left: 1px solid #8c8c8c8c;
            position: relative;
            bottom: 5px;
            height: 100%;

            .mdc-tab-indicator__content {
                opacity: 0;
            }
        }
    }

    .current-tab__info {
        margin-left: 20px;
    }
}
