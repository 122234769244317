@font-face {
    font-family: 'Proxima Nova';
    src: local('Proxima Nova Bold'), local('ProximaNova-Bold'), url('./../../assets/fonts/ProximaNova-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: local('Proxima Nova Regular'), local('ProximaNova-Regular'), url('./../../assets/fonts/ProximaNova-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: local('Proxima Nova Light'), local('ProximaNova-Light'), url('./../../assets/fonts/ProximaNova-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: local('Proxima Nova Black'), local('ProximaNova-Black'), url('./../../assets/fonts/ProximaNova-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}