.mat-mdc-table,
.mat-table {
    mat-icon {
        color: #4A4A4A;
    }
    .mat-column-name {
        a {
            color: $primary-dark;
            font-size: 1.1em;
        }
    }
    &.vert-scroll {
        overflow-y: auto;
        max-height: 100vh;
    }
    .manual-refresh {
        margin-top: 55px;
    }
}

.mat-table-sticky {
    opacity: 0.95;
}

.filter-not-found {
    font-weight: 300;
    font-size: 1.6em;
    line-height: 1.6em;
    text-align: center;
    margin: 50px auto;
    button {
        font-weight: 300;
        font-size: 1em;
    }
}

.not-found {
    font-weight: 300;
    font-size: 1.6em;
    line-height: 4em;
    text-align: center;
    &:not(.mat-row) {
        line-height: 1.4em;
        margin: 50px;
    }
    button {
        font-weight: 300;
        font-size: 1em;
    }
}

.mat-mdc-header-cell,
.mat-header-cell,
.mat-header-cell .mat-sort-header-content {
    letter-spacing: -0.5px;
    line-height: 1em;
}

.mat-mdc-header-row,
.mat-header-row {
    border-bottom-width: 2px !important;
    font-weight: bold !important;
    box-shadow: 0 2px 5px #e5e5e5;
}

.mat-mdc-row,
.mat-row {
    &:last-child {
        border-bottom: none;
    }
    &:nth-child(even) {
        background-color: transparent;
        // background-color: $white;
        // background-image: -webkit-linear-gradient(left, $white, $light-inverse);
        // background-image: linear-gradient(to right, $white, $light-inverse);
    }
    &:nth-child(odd) {
        background-color: $light-inverse;
        background-image: -webkit-linear-gradient(left, $light-inverse, $light);
        background-image: linear-gradient(to right, $light-inverse, $light);
    }
}

.group-row {
    transition: background ease-in-out 0.5s;
    .expanded-ctrl {
        transition: opacity ease-in-out 0.1s;
        opacity: 0;
        margin-left: 10px;
        margin-top: 2px;
        .mat-icon {
            font-size: 2em;
        }
    }
    &:hover {
        background: rgba(0, 0, 0, 0.1);
        .expanded-ctrl {
            opacity: 1;
        }
    }
    .mat-cell {
        .mat-icon {
            color: #606060;
            font-size: 1.6em;
        }
        &.group-expanded {
            .expanded-ctrl {
                opacity: 1 !important;
            }
            // :not(.expanded-ctrl) {
            //   .mat-icon {
            //     color: #5b68db;
            //   }
            // }
        }
    }
    .mat-column-groupSummary {
        max-width: 100px;
        text-align: right;
        .count-badge {
            background: #606060;
            border-radius: 50%;
            padding: 2px 8px;
            color: #fff;
            font-size: 0.9em;
            box-shadow: 0px 1px 5px #00000050;
            margin: 0 10px;
        }
    }
}

.mat-column-group {
    min-height: 52px;
    &.mat-mdc-cell {
        h3 {
            margin: 12px;
            line-height: 1em;
            font-weight: bold;
        }
    }
}

.mat-column-select {
    flex: 0 0 15% !important;
    min-width: 50px !important;
    justify-content: center;
    .selection-index {
        position: relative;
        span {
            cursor: default;
            position: absolute;
            font-size: 0.8em;
            font-weight: 600;
            top: -24px;
            left: -8px;
            color: #6e6e6e;
        }
    }
    &:first-of-type {
        padding-left: 10px !important;
    }
}

.mat-cell:first-of-type,
.mat-header-cell:first-of-type,
.mat-footer-cell:first-of-type {
}

.mat-column-control {
    flex: 0 0 10% !important;
    justify-content: center;
    max-width: 65px;
    &.mat-header-cell,
    &.mat-mdc-cell {
        &.two-items {
            flex: 0 0 14% !important;
        }
    }
}

.mat-column-controlDelete {
    flex: 0 0 10% !important;
    justify-content: center;
    .mat-icon {
        font-size: 1.2em;
    }
}

.mat-column-id {
    flex: 0 0 10%;
}

.mat-column-contentId,
.mat-column-assetName,
.mat-column-filename,
.mat-column-pushUrl,
.mat-column-name {
    // flex: 0 0 20% !important;
    max-width: 60%;
    min-width: 40px;
    &.mat-mdc-cell {
        line-height: 1.1rem;
        letter-spacing: -0.4px;
    }
}

// .mat-column-address,
// .mat-column-descr,
.mat-column-description {
    flex: 0 0 30% !important;
    &.mat-mdc-cell {
        letter-spacing: -0.4px;
    }
}

// .mat-column-pushUrl {
//     &.mat-mdc-cell {
//         letter-spacing: -1px;
//     }
// }
// .mat-column-pushBitrate,
// .mat-column-meta {
//     justify-content: center;
//     text-align: center;
//     font-size: .9em;
//     line-height: 1em;
//     letter-spacing: -0.5px;
//     flex-direction: column !important;
//     max-width: 120px;
// }
// .mat-column-pushBitrate {
//     max-width: 100px;
// }
// .mat-column-quality {
//     flex: 0 0 15% !important;
//     justify-content: center;
//     text-align: center;
//     max-width: 100px;
//     mat-chip {
//         box-shadow: 1px 1px 4px rgb(0 0 0 / 6%);
//         transition: all 0.5s;
//         min-height: 24px;
//         font-size: 0.9em;
//         letter-spacing: -0.5px;
//         &.q-normal {
//             border: 2px solid #ffc65d;
//             background: transparent;
//         }
//         &.q-good {
//             border: 2px solid #98ff5d;
//             background: transparent;
//         }
//     }
// }
// .mat-column-connectedState {
//     flex: 0 0 10% !important;
//     justify-content: center;
//     text-align: center;
//     mat-chip {
//         box-shadow: 1px 1px 4px rgb(0 0 0 / 6%);
//         transition: all 0.5s;
//         min-height: 24px;
//         font-size: 0.9em;
//         letter-spacing: -0.5px;
//     }
// }
// .mat-column-thumb {
//     flex: 0 0 12% !important;
//     img {
//         cursor: pointer;
//         // width: 64px;
//         width: 86px !important;
//         margin: 2px auto;
//         box-shadow: 1px 1px 4px #747474;
//         border-radius: 2px;
//         &.active-false {
//             cursor: default;
//             opacity: 0.4;
//         }
//     }
// }
// .mat-column-sort {
//     //flex: 0 0 15% !important;
//     min-width: 50px;
//     justify-content: center;
// }
// .mat-column-created_at,
// .mat-column-updated_at,
.mat-column-duration,
.mat-column-dateStart,
.mat-column-dateStop,
.mat-column-startDate,
.mat-column-stopDate {
    justify-content: center;
    text-align: center;
    max-width: 100px;
    &.mat-mdc-cell {
        font-size: 0.9em;
        line-height: 1.1rem;
        font-weight: 400;
        letter-spacing: -0.4px;
        &.infinity {
            // font-size: 18px !important;
        }
    }
}

.mat-column-created_at,
.mat-column-updated_at,
.mat-column-createDate,
.mat-column-updateDate {
    flex: 0 0 15% !important;
    justify-content: center;
    text-align: center !important;
    max-width: 100px;
    &.mat-mdc-cell {
        // font-size: 0.9em;
        line-height: 1.1rem;
        font-weight: 400;
        letter-spacing: -0.5px;
    }
}

// .mat-column-contentType {
//     justify-content: center;
// }
.mat-column-type {
    flex: 0 0 10% !important;
    justify-content: center;
    .mat-chip {
        font-size: 0.9em;
        padding: 5px 10px;
    }
}

.mat-column-measure {
    &.mat-mdc-cell {
        font-size: 0.9em;
        // line-height: 1.1rem;
        font-weight: 400;
        letter-spacing: -0.5px;
    }
}

.mat-column-min,
.mat-column-max {
    flex: 0 0 5% !important;
    justify-content: center;
    &.mat-mdc-cell {
        font-size: 0.9em;
        // line-height: 1.1rem;
        font-weight: 400;
        letter-spacing: -0.5px;
    }
    .mat-chip {
        font-size: 0.9em;
        padding: 5px 10px;
    }
}

.mat-column-actions {
    justify-content: center;
    text-align: center;
}

.mat-column-controlEdit,
.mat-column-controlRemove,
.mat-column-controlLocation,
.mat-column-controlInfo {
    justify-content: center;
    text-align: center;
    // max-width: 60px;
    max-width: 40px;
}

.mat-column-token {
    flex: 0 0 15% !important;
    // justify-content: center;
    // text-align: center !important;
    min-width: 180px;
    // min-width: 250px;
}

// .mat-column-status {
//     max-width: 100px;
// }
@keyframes scaleIn {
    from {
        transform: scale(0.5, 0.5);
        opacity: 0.5;
    }
    to {
        transform: scale(2.5, 2.5);
        opacity: 0;
    }
}

.list-controls {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    &.small {
        padding: calc(15px / 2);
        .mat-icon-button {
            // line-height: 40px;
            .mat-icon {
                // width: 20px;
                // height: 20px;
                font-size: 1.3rem;
            }
        }
    }
}

.time-start-cell,
.time-stop-cell {
    a {
        cursor: pointer;
    }
    u {
        border-bottom: 1px dotted #000;
        text-decoration: none;
    }
}

.w-75 {
    width: 75px;
}

.w-100 {
    width: 100px;
}

.mat-paginator {
    &.hidden {
        display: none !important;
    }
}

//TODO
// .sec1,
// .sec3 {
//   .mat-paginator {
//     display: block;
//     position: absolute;
//     bottom: 0;
//     width: 100%;
//     opacity: 0;
//     transition: opacity ease-in-out .2s;
//   }
//   &:hover {
//     .mat-paginator {
//       opacity: .95;
//     }
//   }
// }
// .sec3 {
//   .table-filters {
//     text-align: center;
//     .mat-form-field {
//       max-width: 90%;
//       // padding: 0 !important;
//       margin-bottom: -1em;
//     }
//   }
// }
.mat-tab-body-content {
    // overflow: hidden !important;
}

.progress-cell {
    padding: 0 !important;
}

