mat-card {
    height: 100%;
    mat-card-content {
        // margin: 5px 10px 10px;
    }
    mat-card-title {
        margin: 10px !important;
        font-weight: bold;
        font-family: "Proxima Nova";
        font-size: 16px;
        line-height: 32px;
        letter-spacing: -0.0710702px;
        color: #4a4a4a;
        text-transform: uppercase;
        position: relative;
    }
    mat-card-subtitle {
        font-family: "Proxima Nova";
    }

    .mat-card-header,
    .filters-form {
        margin-bottom: 15px;
    }

    .footer-actions,
    .mat-card-footer {
        display: flex;
        flex-direction: row;
        // justify-content: space-evenly;
        margin-top: 15px;
        padding: 15px;
        background: whitesmoke;
        border-radius: 4px;
        app-button {
            margin: 0 10px;
            &:first-child {
                margin-left: 0;
            }
        }

    }

}

app-nodes-edit-panel {
    mat-card {
        margin: 0 0 35px;
        background-color: #f0f0f0b0;
    }
}
