.mat-mdc-form-field,
mat-form-field {
    margin-bottom: 10px;
    width: 100%;
}

.mat-vertical-content-container {
    // padding-top: 15px;
    .mat-vertical-content {
        padding: 14px 24px;
    }
}

.stepper-nav-buttons {
    display: flex;
    margin: 15px 0 0;
}
