.mdc-list-item.mdc-list-item--with-one-line {
    height: var(--mdc-list-list-item-one-line-container-height / 2);
}

// .mat-mdc-list-base {
//     --mdc-list-list-item-one-line-container-height: 24px;
//     --mdc-list-list-item-two-line-container-height: 32px;
//     --mdc-list-list-item-three-line-container-height: 44px;
// }
// md-checkbox.md-default-theme.md-checked .md-icon {
//   background: orange;
// }