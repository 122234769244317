// /* TODO: завести переменные */
// /* Описание секции */
// section, .section {
//     > *:not(:last-child) {
//         margin-bottom: 8px;
//     }
//     &:not(:last-child) {
//         margin-bottom: 16px;
//     }
// }

/* Описание стилей строки */

.imus-row {
    display: flex;
    align-items: center;
    gap: 8px;
    /* Описание заголовка */
    &.title {
        font-weight: 600!important;
    }
    &:not(:last-child) {
        margin-bottom: 8px;
    }
    &.start {
        align-items: flex-start;
    }
}


/* Описание стилей элемента строки */

.imus-row-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}


/* Описание стилей панели */

.imus-card {
    background-color: #FFFFFF;
    padding: 27px 25px;
    display: block;
}

/* Описание стилей формы */
.imus-form-item {
    gap: unset;
}

/* Модификатор для расположения элемента в блоке справа */
.right {
    margin-left: auto;
}

field-errors {
    margin-top: -5px;
}
