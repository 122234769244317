
.mat-mdc-button.mat-mdc-button-base, .mat-mdc-raised-button.mat-mdc-button-base, .mat-mdc-unelevated-button.mat-mdc-button-base, .mat-mdc-outlined-button.mat-mdc-button-base {
    height: 42px;
}

.mdc-button,
.mat-mdc-button {
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    letter-spacing: -0.5px;
    &.mat-default {
        background-color: #ffffff !important;
        color: #545454 !important;
    }

    &.large {
        height: 2.6em;
        font-size: 1.15em;

        line-height: 1em;
        .mat-icon.btn-icon {
            font-size: 24px;
            width: 24px;
            height: 24px;
        }
    }
    // .mdc-button__label+.mat-icon[iconpositionend] {
    //     margin-left: 0;
    //     margin-right: 8px;
    // }
    .mdc-button__label+.mat-icon,
    .mdc-button__label+.mat-icon[iconpositionend] {
        margin-left: 0;
        margin-right: 0;
    }
}

.mat-mdc-icon-button {
    mat-icon {
        transition: color .5s;
        color: #4A4A4A;
    }
    &:hover,
    &.active {
        mat-icon {
            color: #1e88e5;
        }
    }
}
