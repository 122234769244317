.mat-mdc-dialog-container,
.mdc-dialog__container {
    overflow: hidden !important; //TODO Временно, найти что за лоадер спинер появляется снизу
}

// .mat-mdc-dialog-container,
mat-dialog-container {
    display: block;
    // padding: 24px;
    // border-radius: 4px;
    box-sizing: border-box;
    // overflow: auto;
    outline: 0;
    // width: 100%;
    // height: 100%;
    // min-height: inherit;
    // max-height: inherit;
    .close-button {
        position: absolute;
        z-index: 10;
        top: 12px;
        right: 12px;
        &:hover {
            mat-icon {
                transform: rotate(180deg);
            }
        }
        mat-icon {
            // font-size: 28px;
            // width: 28px;
            // height: 28px;
            transition: 1s ease-in-out;
        }
    }
    // .mat-mdc-dialog-title {
    //     font: 300 22px/38px 'Proxima Nova', sans-serif;
    // }
    mat-dialog-content {
        overflow: hidden;
        // margin: 24px;
    }
    mat-dialog-header {
        padding: 0 82px 0 24px;
        min-height: 70px;
        display: block;
        border-bottom: 1px solid rgba(50, 50, 50, .2);
        box-shadow: 0 1px 12px 0px #0000001a;
        h2 {
            padding: 4px 16px 24px !important;
            display: flex;
            font-family: $headingfont !important;
            // line-height: 30px !important;
            font-size: 26px !important;
            line-height: 1.1rem;
            font-weight: 400;
            letter-spacing: -0.5px !important;
        }
        .mat-dialog-header-buttons {
            position: absolute;
            right: 112px;
            top: 12px;
        }
    }
    .mat-mdc-dialog-actions,
    mat-dialog-actions {
        padding: 8px 16px;
        justify-content: space-between !important;
        mat-paginator {
            .mat-mdc-paginator-page-size-label {
                font-size: .9em;
            }
        }
    }
}

.mat-dialog-height-transition {
    transition: height 0.5s;
}

.mat-dialog-transition {
    transition: all 0.5s;
}
